<template>
    <div class="container" v-if="$i18n.locale == 'it'">
        <div class="row">
            <div class="col s12 left-align">
                <h3>Come Installare e Configurare Metamask</h3>
                <p>
                    Per interagire con questa applicazione è necessario installare Metamask e configurarlo opportunamente.<br>
                    Per farlo è sufficiente seguire i 4 passi riportati di seguito. 
                </p>
                <p>
                    <ul class="browser-default">
                        <ol>
                            <li>
                                <h5>Installare Metamask</h5>
                                <p>
                                    Matamask è un estensione per browser disponibile per Chrome e Firefox.<br>
                                    Il primo passo da fare è installare il plug-in di metamask nel proprio browser.<br>
                                    Fatto questo, appare l' icon di una volpe nella barra del browser.<br>
                                    Per installare l'estensione MetaMask nel tuo browser puoi scaricarla dal sito ufficiale <a href="https://metamask.io/download" target="_blank">https://metamask.io/download</a>
                                </p>
                            </li>
                            <li>
                                <h5>Creare Account in Metamask</h5>
                                <p>
                                    Fare click su "Creare Wallet".<br>
                                    Metamask genererà le nuove chiavi per l' account e sarà necessario anche impostare una password che protegga le chiavi appena create.<br>
                                    Metamask mostrerà una lista di 12 parole che è necessario appuntare insieme alla password su un pezzo di carta che va conservato con la massima cura.<br>
                                </p>

                            </li>
                            <li>
                                <h5>Connettere Metamask alla Binance Smart Chain (BSC)</h5>
                                <p>
                                    Per default, Metamask si connette alla rete Ethereum. Però per utilizzare questa applicazione bisogna  <router-link to="/collegare-metamask"> connetterlo alla Binance Smart Chain.</router-link><br>
                                    Cliccare sulla foto dell' Avatar dell' account e poi, click su "Impostazioni".<br>
                                    Poi selezionare "Networks" e dunque "Aggiungere Rete". Si aprirà un form che occorre compilare con i seguenti dati:
                                    <ul class="browser-default">
                                        <li>
                                            <b>Nome della Rete:</b> BSC
                                        </li>
                                        <li>
                                            <b>Nuovo URL RCP:</b> https://bsc-dataseed.binance.org/
                                        </li>
                                        <li>
                                            <b>ChainID:</b> 56
                                        </li>
                                        <li>
                                            <b>Simbolo:</b> BNB
                                        </li>
                                        <li>
                                            <b>URL del Block Explorer:</b> https://bscscan.com
                                        </li>
                                    </ul>
                                    <br>
                                    Infine salvare i dati inseriti.
                              <p v-if="$i18n.locale == 'en'">
                                <iframe width="760" height="415" id="video" src="https://www.youtube.com/embed/99JFQuvM6M4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                              </p>
                              <p v-else-if="$i18n.locale == 'es'">
                                <iframe width="760" height="415" src="https://www.youtube.com/embed/Bj0_Kyp45Jc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                              </p>
                              <p v-else>
                                <iframe width="760" height="415" src="https://www.youtube.com/embed/8hS2BEunE4E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                              </p>
                                </p>
                            </li>
                            <li>
                                <h5>Aggiungere il Token USDT</h5>
                                <p>
                                    Per aggiungere il token ERC20 USDT che gira sulla Binance Smart Chain, fare click su "Aggiungi Token".<br>
                                    Nel campo "Indirizzo dello Smart Contract", bisogna incollare il seguente indirizzo:
                                    <br><br>
                                    <b>0x55d398326f99059fF775485246999027B3197955</b>
                                    <br><br>
                                    Gli altri campi saranno completati in automatico. Cliccare su "Avanti".
                                </p>
                            </li>
                        </ol>
                    </ul>
                </p>
            </div>
        </div>
    </div>
    <div class="container" v-if="$i18n.locale == 'en'">
    <div class="row">
      <div class="col s12 left-align">
        <h3>How to install and configure MetaMask</h3>
        <p>
          To interact with this app, installing MetaMask and configuring it correctly is necessary.<br>
          To do this, just follow the 4 steps that are indicated in continuation.
        </p>
        <p>
        <ul class="browser-default">
          <ol>
            <li>
              <h5>Installing MetaMask</h5>
              <p>
                MetaMask is an extension of the browser available for Chrome and Firefox.<br>
                The first step is to install the MetaMask add-on to your browser.<br>
                Once you’ve done this, a fox icon appears on your browser bar.<br>
                To install the MetaMask extension to your browser, you can download it from the official site <a href="https://metamask.io/download" target="_blank">https://metamask.io/download</a>
              </p>
            </li>
            <li>
              <h5>Creating an account on MetaMask </h5>
              <p>
                Click on “Create Wallet”.<br>
                MetaMask will generate the new keys for the account and it will also be necessary to establish a password to protect the recently created keys.<br>
                MetaMask will show a list of 12 words which will have to be noted down together with the password on a sheet of paper and carefully guarded.<br>
              </p>

            </li>
            <li>
              <h5>Connecting MetaMask to the Binance Smart Chain</h5>
              <p>
                By default, MetaMask is connected to the Ethereum network. However, to use this app you need to <router-link to="/collegare-metamask">connect it to the Binance Smart Chain.</router-link><br>
                Click on your account’s avatar photo and then, on “configuration”. <br>
                At continuation, select “Networks” and then “Add Network”. A form will open and you will have to fill it out with the following data:
              <ul class="browser-default">
                <li>
                  <b>Name of the network: </b> BSC
                </li>
                <li>
                  <b>New URL of the RCP:</b> https://bsc-dataseed.binance.org/
                </li>
                <li>
                  <b>ChainID:</b> 56
                </li>
                <li>
                  <b>Symbol:</b> BNB
                </li>
                <li>
                  <b>URL of the Explorer Block:</b> https://bscscan.com
                </li>
              </ul>
              <br>
              And last, guard the data introduced.
              <p v-if="$i18n.locale == 'en'">
                <iframe width="760" height="415" id="video" src="https://www.youtube.com/embed/99JFQuvM6M4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </p>
              <p v-else-if="$i18n.locale == 'es'">
                <iframe width="760" height="415" src="https://www.youtube.com/embed/Bj0_Kyp45Jc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </p>
              <p v-else>
                <iframe width="760" height="415" src="https://www.youtube.com/embed/8hS2BEunE4E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </p>
              </p>
            </li>
            <li>
              <h5>Add USDT token</h5>
              <p>
                to add the ERC20 USDT token that operates on the Binance Smart Chain, click on “Add Token”.<br>
                In the field “Smart Contract address”, you must paste the following address:
                <br><br>
                <b>0x55d398326f99059fF775485246999027B3197955</b>
                <br><br>
                The remaining fields will be completed automatically. Click on “Next”.
              </p>
            </li>
          </ol>
        </ul>
        </p>
      </div>
    </div>
  </div>
    <div class="container" v-if="$i18n.locale == 'es'">
    <div class="row">
      <div class="col s12 left-align">
        <h3>Cómo instalar y configurar MetaMask</h3>
        <p>
          Para interactuar con esta aplicación es necesario instalar MetaMask y configurarlo adecuadamente.<br>
          Para ello, basta con seguir los 4 pasos que se indican a continuación.
        </p>
        <p>
        <ul class="browser-default">
          <ol>
            <li>
              <h5>Instalar MetaMask</h5>
              <p>
                MetaMask es una extensión del navegador disponible para Chrome y Firefox.<br>
                El primer paso es instalar el complemento de MetaMask en tu navegador.<br>
                Una vez hecho esto, aparece un icono de un zorro en la barra del navegador.<br>
                Para instalar la extensión MetaMask en tu navegador, puedes descargarla desde la web oficial <a href="https://metamask.io/download" target="_blank">https://metamask.io/download</a>
              </p>
            </li>
            <li>
              <h5>Crear una cuenta en MetaMask </h5>
              <p>
                Haga clic en "Crear monedero".<br>
                MetaMask generará las nuevas claves para la cuenta y también será necesario establecer una contraseña para proteger las claves recién creadas.<br>
                MetaMask mostrará una lista de 12 palabras que deberá anotar junto con la contraseña en un papel y guardarlo cuidadosamente<br>
              </p>

            </li>
            <li>
              <h5>Conectar MetaMask a Binance Smart Chain</h5>
              <p>
                Por defecto, MetaMask se conecta a la red Ethereum. Sin embargo, para utilizar esta aplicación es necesario <router-link to="/collegare-metamask">conectarla a Binance Smart Chain.</router-link><br>
                Haz clic en la foto del avatar de tu cuenta y luego en "Configuración".<br>
                A continuación, selecciona "Redes" y luego "Añadir red". Se abrirá un formulario y tendrás que rellenarlo con los siguientes datos:
              <ul class="browser-default">
                <li>
                  <b>Nombre de la red: </b> BSC
                </li>
                <li>
                  <b>Nueva URL del RCP:</b> https://bsc-dataseed.binance.org/
                </li>
                <li>
                  <b>ChainID:</b> 56
                </li>
                <li>
                  <b>Símbolo:</b> BNB
                </li>
                <li>
                  <b>URL del Block Explorer:</b> https://bscscan.com
                </li>
              </ul>
              <br>
              Por último, guarda los datos introducidos.
              <p v-if="$i18n.locale == 'en'">
                <iframe width="760" height="415" id="video" src="https://www.youtube.com/embed/99JFQuvM6M4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </p>
              <p v-else-if="$i18n.locale == 'es'">
                <iframe width="760" height="415" src="https://www.youtube.com/embed/Bj0_Kyp45Jc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </p>
              <p v-else>
                <iframe width="760" height="415" src="https://www.youtube.com/embed/8hS2BEunE4E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </p>
              </p>
            </li>
            <li>
              <h5>Añadir token USDT</h5>
              <p>
                Para añadir el token ERC20 USDT que opera en Binance Smart Chain, haga clic en "Añadir Token".<br>
                En el campo "Dirección del contrato inteligente", debe pegar la siguiente dirección:
                <br><br>
                <b>0x55d398326f99059fF775485246999027B3197955</b>
                <br><br>
                Los demás campos se completarán automáticamente. Haga clic en "Siguiente".
              </p>
            </li>
          </ol>
        </ul>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
    h6{
        font-weight: bold;
    }
    .example{
        max-width: 100%;
        margin-top: 40px;
        margin-bottom: 150px;
    }

    .divide{
        height: 2px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 600px){
        .example{
            margin-bottom: 30px;
        }
    }

</style>
